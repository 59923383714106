<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/programs/ra-aus-navigation-endorsement-800.jpg')" title="RA-AUS NAVIGATION ENDORSEMENT" subtitle="The freedom to navigate" />
    <div class="program-description">
      <h1>What is a Navigation Endorsement?</h1>
      <p>
        If the Archerfield Training Area isn't big enough for you, and you want to stretch your wings, it's time for a Navigation Endorsement. This Endorsement allows you to pilot an RA-Aus aeroplane outside the 25 nautical mile limit. If you wanted to fly to Glen Innes or Emerald, you can do just that. We teach you the lost art of navigation by clock and compass &mdash; so you won't even need the use of a GPS!
      </p>

      <br>

      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li>12 hours navigation experience including 2 hours solo navigation.</li>
        </ul>
      </div>

      <br>

      <p>
        <b>Note:</b> Flights must take place outside of controlled airspace.
      </p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
